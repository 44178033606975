import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ChatWidget } from "@papercups-io/chat-widget";
import { WhatsApp, Instagram, LinkedIn, Twitter, MusicNoteOutlined } from '@material-ui/icons';
import first from "./1.jpeg";
import second from "./2.jpeg";
import third from "./3.jpeg";
// import fourth from "./4_1.jpg";
import fifth from './5.jpeg';


import ReactGA from "react-ga";

const theme = createMuiTheme({});

const containerStyle = {
	paddingLeft: "0",
	paddingRight: "0"
}

const buttonStyle = {
	fontFamily: '"Work Sans", Roboto, Helvetica, Arial, sans-serif',
	fontSize: "16px",
	color: "#ffffff",
	backgroundColor: " #1d2d5e",
	border: "1px solid #000000",
	borderRadius: "5px",
	borderBottom: "2px solid #000000",
	margin: "20px 2% 60px",
	width: "96%",
	textTransform: "capitalize",
};

const iconButtonStyle = {
	fontFamily: '"Work Sans", Roboto, Helvetica, Arial, sans-serif',
	textAlign: "center",
	fontSize: "14px",
	border: "1px solid #cddddd",
	borderRadius: "5px",
	borderBottom: "2px solid #cddddd",
	marginTop: "10px",
	marginLeft: "2%",
	minWidth: "24%",
	textTransform: "capitalize"
};

ReactGA.initialize("UA-192980347-1");
ReactGA.pageview('main');

export default class App extends Component {
	clickWhatsapp = () => {
		ReactGA.event({
			category: "Button",
			action: "Click the Whatsapp Chat Button",
		});
	};
	clickInstagram = () => {
		ReactGA.event({
			category: "Button",
			action: "Click the Instagram Button",
		});
	};
	clickTikTok = () => {
		ReactGA.event({
			category: "Button",
			action: "Click the TikTok Button",
		});
	};
	clickTwitter = () => {
		ReactGA.event({
			category: "Button",
			action: "Click the Twitter Button",
		});
	};
	clickLinkedIn = () => {
		ReactGA.event({
			category: "Button",
			action: "Click the LinkedIn Button",
		});
	};
	render() {
		const currentYear = new Date().getFullYear();
		return (
			<ThemeProvider theme={theme}>
				<Container maxWidth="sm" style={containerStyle}>
					<div className="logo">
						<img src={first} alt="First" id="logo-header" />
					</div>
					<div className="flyer">
						<img src={second} alt="Second" id="logo-header"  width="100%"/>
					</div>
					<div className="flyer">
						<img src={third} alt="Third" id="logo-header"  width="100%"/>
					</div>
					<div className="flyer">
						<img src={fifth} alt="Fifth" id="logo-header"  width="100%"/>
					</div>
					<Button
						style={buttonStyle}
						fullWidth
						variant="outlined"
						size="large"
						target="_blank"
						href="https://lynk.id/aksel/"
						id="link-to-mentor-class"
					>
						Lihat Mentor Class →
					</Button>
					<IconButton
						onClick={this.clickWhatsapp}
						style={iconButtonStyle}
						fullWidth
						variant="outlined"
						size="large"
						target="_blank"
						href="https://wa.me/6285159788221"
						id="link-to-chat-whatsapp"
					>
						<WhatsApp />
						Chat Whatsapp
					</IconButton>
					<IconButton
						onClick={this.clickInstagram}
						style={iconButtonStyle}
						fullWidth
						variant="outlined"
						size="large"
						target="_blank"
						href="https://www.instagram.com/aksel.official/"
						id="link-to-ig"
					>
						<Instagram />
						Instagram
					</IconButton>
					<IconButton
						onClick={this.clickLinkedIn}
						style={iconButtonStyle}
						fullWidth
						variant="outlined"
						size="large"
						target="_blank"
						href="https://www.linkedin.com/company/aksel-official/"
						id="link-to-linkedin"
					>
						<LinkedIn />
						LinkedIn
					</IconButton>
					<IconButton
						onClick={this.clickTwitter}
						style={iconButtonStyle}
						fullWidth
						variant="outlined"
						size="large"
						target="_blank"
						href="https://twitter.com/akselofficial_"
						id="link-to-twitter"
					>
						<Twitter />
						Twitter
					</IconButton>
					<IconButton
							onClick={this.clickTikTok}
							style={iconButtonStyle}
							fullWidth
							variant="outlined"
							size="large"
							target="_blank"
							href="https://www.tiktok.com/@aksel.official"
							id="link-to-tiktok"
						>
							<MusicNoteOutlined />
							TikTok
					</IconButton>
					<div className="footer">
						<center>Contact Us</center>
						<center>team@aksel.co.id</center>
						<center>©{currentYear} Aksel (PT Kreasi Pemuda Bangsa)</center>
					</div>
				</Container>
				<ChatWidget
					accountId="ad89afde-1101-4446-a0cd-1dd2371659ca"
					title="Welcome to Aksel"
					subtitle="Ask us anything in the chat window below"
					primaryColor="#1d2d5e"
					greeting=""
					awayMessage=""
					newMessagePlaceholder="Start typing..."
					showAgentAvailability={false}
					agentAvailableText="We're online right now!"
					agentUnavailableText="We're away at the moment."
					requireEmailUpfront={false}
					iconVariant="outlined"
					baseUrl="https://aksel-papercups.herokuapp.com"
				/>
			</ThemeProvider>
		);
	}
}
